<template>
  <div class="wrapper">
    <!--
	<div class="kaztravel-header">
		<div class="kaztravel-header-top">
			<img src="../assets/img/logo-kt-full.png" alt="KazTourism"/>
			<div class="kaztravel-header-top-open" id="kazakhTourismHeaderTop">
				<div class="kaztravel-header-top-open-item open-item-1"></div>
				<div class="kaztravel-header-top-open-item open-item-2"></div>
				<div class="kaztravel-header-top-open-item open-item-3"></div>
			</div>
		</div>
		<div id="kazakhTourismMainMenu" class="kaztravel-header-menu">
			<div class="kaztravel-header-sideblock" id="kazakhTourismSideBlock"></div>
			<div class="kaztravel-header-content" id="kazakhTourismMainContent">
			</div>
		</div>
	</div>
	<div style="width:100%; height: 72px"></div>
-->
    <v-header @mobileActive="mobileActive"></v-header>
    <v-mobile-header @mobileActive="mobileActive"></v-mobile-header>

    <router-view />

    <v-footer></v-footer>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      mobileStatus: false,
    }
  },
  methods: {
    mobileActive(status) {
      this.mobileStatus = status
    },
  },
  watch: {},
}
</script>

<style>
@import './../assets/css/bootstrap.min.css';

@import './../assets/css/swiper.min.css';

@import './../assets/css/style.css';
@import './../assets/css/media.css';

*,
::before,
::after {
  box-sizing: border-box;
}

.kaztravel-header {
  position: fixed;
  z-index: 1000;
  width: 100%;
}
.kaztravel-header-top {
  top: 0;
  background: #fff;
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}
.kaztravel-header-top img {
  width: 49px;
  height: 46px;
  margin-right: 25px;
}
.kaztravel-header-top-open {
  height: 24px;
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
}
.kaztravel-header-top-open-item {
  height: 2px;
  background-color: #67a1d6;
  border-radius: 15px;
  transition: 0.5s ease-out;
}
.kaztravel-header-menu {
  position: fixed;
  top: 72px;
  left: 0;
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(3px);
  z-index: 100;
  transition: 0.5s ease-in-out;
  display: flex;
  background-color: rgba(47, 46, 65, 0.9);
  background-size: cover;
  background-repeat: no-repeat;
}
.kaztravel-header-sideblock {
  min-width: 645px;
  height: 100%;
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}
.kaztravel-header-sideblock:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #67a1d6;
  left: 0;
  top: 0;
  z-index: -1;
}
.kaztravel-header-sideblock-item {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 48px;
  line-height: 59px;
  color: #a0c9ef;
  margin-bottom: 45px;
  cursor: pointer;
  padding: 5px 69px 5px 5px;
}
.kaztravel-header-sideblock-item-active {
  color: #2e6495;
  border-right: 5px solid #f5c93c;
  transition: color 0.3s ease-in;
  padding-right: 64px;
}
.kaztravel-header-content {
  height: 100%;
  width: 100%;
  padding-top: 110px;
  padding-bottom: 60px;
  overflow: auto;
}
.kaztravel-header-content-item {
  display: flex;
  padding: 5px 5px 5px 45px;
  color: #fff;
  flex-direction: column;
  margin-bottom: 30px;
  transition: 0.3s ease-in;
  text-decoration: none;
}
.kaztravel-header-content-item-title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
.kaztravel-header-content-item-subtitle {
  margin-top: 10px;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
}
.kaztravel-header-content-item-icon {
  margin-left: 15px;
  transition: 0.3s;
}
.kaztravel-header-content-item-back {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin: 0 auto 40px;
  padding: 5px 5px 5px 45px;
  cursor: pointer;
}
.kaztravel-header-content-item-back svg {
  margin-left: 5px;
  transform: rotate(180deg);
}
.kaztravel-header-content-item:hover {
  transition: 0.3s;
  color: #f5c93c;
}
.kaztravel-header-content-item:hover .kaztravel-header-content-item-icon {
  transform: rotate(180deg);
}
.kaztravel-header-content-item:hover .kaztravel-header-content-item-icon path {
  stroke: #f5c93c;
}
@media (min-width: 1000px) and (max-width: 1300px) {
  .kaztravel-header-sideblock {
    min-width: 40%;
  }
}
@media (min-width: 760px) and (max-width: 1000px) {
  .kaztravel-header-sideblock {
    min-width: 300px;
    padding-top: 60px;
  }
  .kaztravel-header-sideblock-item {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 30px;
    padding: 5px 30px 5px 5px;
  }
  .kaztravel-header-content {
    padding-top: 60px;
  }
  .kaztravel-header-content-item-title {
    font-size: 18px;
    line-height: 22px;
  }
  .kaztravel-header-content-item-subtitle {
    font-size: 14px;
    line-height: 18px;
  }
  .kaztravel-header-content-item-icon {
    margin-left: 10px;
    transition: 0.3s;
    width: 15px;
    height: 15px;
  }
}
@media (min-width: 560px) and (max-width: 760px) {
  .kaztravel-header-sideblock {
    min-width: 200px;
    padding-top: 30px;
  }
  .kaztravel-header-sideblock-item {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
    padding: 5px 20px 5px 5px;
  }
  .kaztravel-header-content {
    padding-top: 30px;
  }
  .kaztravel-header-content-item {
    margin-bottom: 10px;
  }
  .kaztravel-header-content-item-title {
    font-size: 12px;
    line-height: 16px;
  }
  .kaztravel-header-content-item-subtitle {
    font-size: 12px;
    line-height: 14px;
    margin-top: 0px;
  }
  .kaztravel-header-content-item-icon {
    margin-left: 8px;
    transition: 0.3s;
    width: 10px;
    height: 10px;
  }
}
@media (max-width: 560px) {
  .kaztravel-header-sideblock {
    min-width: 100%;
  }
  .kaztravel-header-sideblock-item {
    font-size: 36px;
    line-height: 45px;
    margin: 0 auto 20px;
    padding-right: 0;
    display: flex;
    align-items: center;
  }
  .kaztravel-header-sideblock-item svg path {
    stroke: #a0c9ef;
  }
  .kaztravel-header-sideblock-item-active {
    color: #a0c9ef;
    border-right: none;
    padding-right: 0;
  }
  .kaztravel-header-content {
    padding-top: 60px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #2e6495;
  }
  .kaztravel-header-content-item svg {
    display: none;
  }
}
.open-item-1 {
  width: 6px;
}
.open-item-2 {
  width: 16px;
}
.open-item-3 {
  width: 10px;
}
.open-item-active.open-item-1 {
  width: 16px;
}
.open-item-active.open-item-2 {
  width: 7px;
}
.open-item-active.open-item-3 {
  width: 14px;
}
</style>
